import React from 'react';

interface SpacerProps {
  x: number;
  y: number;
  children: React.ReactNode;
}

const Spacer = (props: SpacerProps) => {
  const classNames = `mx-${props.x} my-${props.y}`;
  return <div className={classNames}>{props.children}</div>;
};

export default Spacer;
